import React from 'react';
import '../../App.css';


function Services() {
  return (
    <>
    
       <div className='content-text'>
         <p>BlueBoy Thai specialises in offering authentic, restaurant quality Thai food. 
           We take the time to source the best quality ingredients locally, to ensure that
           the aromas and flavours transport you to Thailand.
         </p>
         <p></p>
         <p>We can provide an element of luxury to your food experience - 
           whether you require finger food, a sitdown dinner or something more casual,
           we can add some flavour to your event.</p>
           
           <p></p>

           <p>Get in touch to see how we can help you.</p>
       

          
      </div>
       
      <div className='services'>

        <div className='title'>SERVICES</div>

        </div>

    </>
  );
     
    

}

export default Services;