import React from 'react';
import '../../App.css';

function Products() {
  return (
    <>
    
       <div className='content-text'>
         <p>We have a range of products currently under development!
         </p>
         <p></p>
         <ul>
           <li>Reheatable Meals</li>
           <li>Curry Pastes</li>
           <li>Curry Sauces</li>
           <li>Marinades</li>
           <li>Spices</li>
         </ul>
          <p></p>
         <p>Enquire now.</p>
      </div>
       
      <div className='products'>

        <div className='title'>Products</div>

        </div>

      
    </>
  );
     
    

}

export default Products;
