import React from 'react';
import '../../App.css';



function About() {

  
  return (
    <>
    
       <div className='content-text'>
       <h1>Our Story
         </h1>
         <p></p>
         <p>Blueboy Thai is a celebration of authentic 
           Thai flavour that remains true to its origin.
         </p>
         <p></p>
         <p>Developed through years of experience in 
           some of Melbourne's best restaurants and 
           inspired by frequent trips to Thailand, 
           BlueBoy Thai seeks to recreate the life 
           and elegance that is inherent in Thai cuisine.</p>
          <p></p>
           <p>BlueBoy digresses from the sometimes lacklustre but familiar flavours
             of local Thai takeaway and presents vibrant, complex
             and refined flavours that evoke memories of place.</p>
             <p></p>
             <p>Regional, lesser known dishes are championed, varied 
               combinations are created to provide an alternative to 
               the everyday dishes that Australians are accustomed to 
               - green curry doesn't always have to go with chicken.</p>
               <p></p>
               <p>At BlueBoy, we provide a true 
                 experience of Thai cuisine, where the flavour 
                 can be savoured in your own home.</p>
      </div>
       
      <div className='about'>

        <div className='title'>ABOUT US</div>

        </div>

      
     
      
    </>
  );
     
    

}

export default About;
