import React from 'react';
import '../../App.css';

function Contact() {
  return (
    <>
    
       <div className='content-text'>

      </div>
       
      <div className='contact'>

        <div className='title'>Get in Touch</div>

        </div>

      
    </>
  );
    
}

export default Contact;