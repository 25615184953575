import React from 'react';
import '../App.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './HeroSection.css';


function HeroSection() {
  return (
    <div className='hero-container'>

      <h1>A CELEBRATION OF THAI CUISINE</h1>
      <p>An experience of elegant Thai Flavours.</p>
      <div className='hero-btns'>
      
      <Link to='/about' className='btn-mobile'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          Curious?
        </Button></Link>
      </div>
      
    </div>
  );
}

export default HeroSection;
