import React from 'react';
import './Cards.css';
import CardItem from './Carditem';


function Cards() {
  return (
    <div className='cards'>
      <h1>Our Food</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='../images/somtam.jpg'
              text='Som Tam - Green Papaya Salad'
              label='BlueBoy Thai - Plated Food'
              path='/services'
            />
            <CardItem
              src='../images/img-pippi.jpg'
              text='Wok Tossed Pippis with garlic and white pepper'
              label='BlueBoy Thai - Plated Food'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='../images/kingfish.jpg'
              text='Soy Cured Kingfish with watermelon and roasted galangal'
              label='BlueBoy Thai - Finger Food'
              path='/services'
            />
             <CardItem
              src='../images/spread.jpg'
              text='Shared Plates'
              label='BlueBoy Thai - Plated Food'
              path='/services'
            />
            <CardItem
              src='../images/mooping.jpg'
              text='Grilled Pork Skewers'
              label='BlueBoy Thai - Finger Food'
              path='/services'
            />
           
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
